import React, { useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import FairyTaleAlert from '../../components/fairytale-alert'
import MediaFileFormComponent from '../../components/fairytale-form/MediaFileFormComponent'
import useFairyTaleClubMediaFile from '../../hooks/useFairyTaleClubMediaFile'
import useFairyTaleGroups from '../../hooks/useFairyTaleGroups' // Custom hook to fetch groups

const MediaFileSchema = Yup.object().shape({
  file: Yup.mixed().required('File is required'),
  visibility: Yup.string().oneOf(['public', 'group', 'private']).required(),
  groupId: Yup.number().nullable(),
})

const MediaFileUpload: React.FC = () => {
  const { t: tforms } = useTranslation(['formFields'])
  const { t: tpagetexts } = useTranslation(['pageTexts'])
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [alertVariant, setAlertVariant] = useState<'success' | 'danger'>(
    'success',
  )
  const [isLoading, setIsLoading] = useState<boolean>(false) // Loading state for button
  const { createNewMedia } = useFairyTaleClubMediaFile()
  const { groups } = useFairyTaleGroups() // Fetch available groups

  const handleFileUpload = async (
    values: any,
    { setStatus, setErrors, setSubmitting, resetForm }: any,
  ) => {
    const formData = new FormData()
    formData.append('file', values.file)
    formData.append('visibility', values.visibility)
    if (values.visibility === 'group' && values.groupId) {
      formData.append('groupId', String(values.groupId))
    }

    setIsLoading(true) // Start loading
    try {
      const result = (await createNewMedia(formData)) as {
        error: any
        data: any
      }
      if (result.data) {
        setAlertMessage(tforms('fileUploadSuccess'))
        setAlertVariant('success')
        setShowAlert(true)
        setTimeout(() => setShowAlert(false), 5000)
        resetForm()
      } else {
        const message =
          result.error.data.message || tforms('somethingWentWrong')
        setStatus({ success: false })
        setErrors({ submit: message })
        setSubmitting(false)
        setAlertVariant('danger')
        setAlertMessage(message)
        setShowAlert(true)
        setTimeout(() => setShowAlert(false), 5000)
      }
    } catch (error) {
      setAlertMessage(tforms('fileUploadError'))
      setAlertVariant('danger')
      setShowAlert(true)
    } finally {
      setIsLoading(false) // Stop loading after completion
    }
  }

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">{tpagetexts('mediaUploadTitle')}</h1>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <FairyTaleAlert
                variant={alertVariant}
                message={alertMessage}
                heading=""
                show={showAlert}
                name="file-upload"
              />
              <MediaFileFormComponent
                onSubmit={handleFileUpload}
                validationSchema={MediaFileSchema}
                isLoading={isLoading}
                groups={groups} // Pass groups to the form component
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default MediaFileUpload
