import {
  useCreateNewMediaMutation,
  // useDeleteAppMutation,
  // useEditAppMutation,
  useGetMediaFilesQuery,
} from '../redux/appQuery'

const useFairyTaleClubMediaFile = () => {
  const { data: mediaFiles, isLoading, error } = useGetMediaFilesQuery({})
  // const [editApp] = useEditAppMutation()
  // const [deleteApp] = useDeleteAppMutation()
  const [createNewMedia] = useCreateNewMediaMutation()
  return {
    mediaFiles,
    isLoading,
    error,
    createNewMedia,
  }
}

export default useFairyTaleClubMediaFile
