import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Col } from 'react-bootstrap'
import { Eye } from 'react-feather'
import FairyTaleTable from '../../components/fairytale-table'
import DataError from '../../components/DataError'
import FairyTaleAlert from '../../components/fairytale-alert'
import Loader from '../../components/Loader'
import FairytaleModal from '../../components/fairytale-modal'
import useFairyTaleClubMediaFile from '../../hooks/useFairyTaleClubMediaFile'
import { baseUrl } from '../../redux/appQuery'

const MediaFileList = () => {
  const { t: tforms } = useTranslation(['formFields'])
  const { t: tpagetexts } = useTranslation(['pageTexts'])
  const { mediaFiles, isLoading, error } = useFairyTaleClubMediaFile()

  const [showModal, setShowModal] = useState<boolean>(false)
  const [modalMessage, setModalMessage] = useState<string>('')
  const [modalHeading, setModalHeading] = useState<string>('')

  const _handleCloseModal = () => {
    setModalMessage('')
    setModalHeading('')
    setShowModal(false)
  }

  const MEDIA_FILE_COLUMNS: any[] = useMemo(
    () => [
      {
        id: 'image-preview',
        Header: tpagetexts('imagePreview'),
        Cell: ({ row }: { row: any }) => (
          <img
            src={`${baseUrl}${row.original.filePath}`}
            alt="Media Preview"
            style={{ width: '50px', height: '50px', objectFit: 'cover' }}
          />
        ),
      },
      {
        id: 'uploadedBy',
        Header: tpagetexts('uploadedBy'),
        Cell: ({ row }: { row: any }) =>
          `${row.original.uploadedBy.first_name} ${row.original.uploadedBy.last_name}`,
      },
      {
        id: 'visibility',
        Header: tpagetexts('visibility'),
        accessor: 'visibility',
      },
      {
        id: 'group_id',
        Header: tpagetexts('group_id'),
        Cell: ({ row }: { row: any }) =>
          row.original.visibility === 'group'
            ? row.original?.userGroup?.group_id
            : '',
      },
      {
        id: 'uploadedAt',
        Header: tpagetexts('uploadedAt'),
        Cell: ({ row }: { row: any }) =>
          new Date(row.original.uploadedAt).toLocaleString(),
      },
      {
        id: 'action',
        Header: tpagetexts('openInWindow'),
        Cell: ({ row }: { row: any }) => (
          <span>
            <Eye
              className="align-middle mx-3 cursor-pointer"
              size={18}
              data-cy={`media-file-list-actions-view-${row.index}`}
              onClick={() =>
                window.open(`${baseUrl}${row.original.filePath}`, '_blank')
              }
            />
          </span>
        ),
      },
    ],
    [tpagetexts],
  )

  if (error) {
    return <DataError />
  }

  return (
    <React.Fragment>
      <Col>
        <FairyTaleTable
          data-cy="media-files-table-main"
          columns={MEDIA_FILE_COLUMNS}
          data={isLoading ? [] : mediaFiles}
          showHeader={true}
          mainTitle={tpagetexts('mediaFiles')}
          subtitle={tpagetexts('mediaFileListSubtitle')}
          useSearchFilter={true}
        >
          <FairyTaleAlert show={isLoading} variant="light">
            <Loader />
          </FairyTaleAlert>
        </FairyTaleTable>
      </Col>
      <FairytaleModal
        showModal={showModal}
        onHideModal={_handleCloseModal}
        modalHeading={modalHeading}
        modalMessage={modalMessage}
        onClickNegativeButton={_handleCloseModal}
        onClickPositveButton={_handleCloseModal}
        modalButtonTextPositive={tforms('close')}
        modalButtonTextNegative=""
        name="media-files-modal"
      />
    </React.Fragment>
  )
}

export default MediaFileList
