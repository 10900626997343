const en = {
  //Auth
  appNameMain: 'Club application dashboard',
  signinSubTitle: 'Sign in to your account to continue',
  forgotPassword: 'Forgot password?',
  privacy: 'Privacy policy',
  signin: 'Sign in',
  signup: 'Sign up',
  resetPassword: 'Reset password',
  resetPasswordSubtitle: 'Enter your email address to reset your password.',
  //Sidebar
  Home: 'Home',
  Apps: 'Apps',
  Notifications: 'Notifications',
  Messaging: 'Messaging',
  Ads: 'Ads',
  'Apps List': 'Apps List',
  'Create an app': 'Create an app',
  'View integrations': 'View integrations',
  'Add an integration': 'Add an integration',
  'View notifications': 'View notifications',
  'Activate new alert': 'Activate new alert',
  'View scheduled and sent messages': 'View scheduled and sent messages',
  'Send an instant message': 'Send an instant message',
  'All ads': 'All ads',
  'Creat a new ad': 'Creat a new ad',
  'Media and files': 'Media and files',
  'All media': 'All media',
  Upload: 'Upload',
  Users: 'Users',
  'Users List': 'Users List',
  'New user': 'New user',
  createNewUserMainTitle: 'Create new user',
  //Users
  firstname: 'First Name',
  lastname: 'Last Name',
  usersListSubTitle: 'Displaying all users',
  userProfileHeader: 'User details',
  userProfileSkills: 'Skills',
  userProfileAbout: 'About',
  //App
  App: 'App',
  Name: 'Name',
  app: 'App',
  appOwner: 'App name',
  Status: 'Status',
  app_id: 'App ID',
  app_store_id: 'App Store ID',
  app_title: 'App name',
  'Last updated': 'Last update',
  ownerRole: 'Owner role',
  appPageSubtitle: 'Basic information of the new app',
  mediaUploadTitle: 'Upload a new media',
  editInProgress: 'You are editing ',
  lowercaseApp: 'app',
  searchFrom: 'Search from',
  records: '... data',
  deleteApp: 'Delete App',
  Actions: 'Actions',
  appsSubTitle: 'Displaying all apps registered on Fairytale',
  //App editing
  component: 'Settings',
  newFeedCreateText: 'Create new feed item',
  feedsNnotifications: 'Feeds',
  basicInfo: 'Basic Information',
  token: 'Token',
  user_group: 'App owner',
  //Feeds
  feeds: 'feeds',
  feedName: 'Feed name',
  status: 'Status',
  pushStatus: 'Push status',
  deleteFeed: 'Delete feed',
  feedsListPageHeaderText: 'Feeds for App',
  feedPageCreateTitle: 'Create new feed',
  isAlive: 'Is the integration live?',
  integrationStatus: 'Integration status',
  deleteIntegration: 'Remove integration',
  appIntegrations: 'Integrations',
  integrationPageCreateTitle: 'Create new app integration',
  integrationName: 'Integration name',
  //Ads and campaigns
  tabTitle: 'Ads and Campaigns',
  campaignTitle: 'Title',
  camapignDescription: 'Description',
  campaignViews: 'Views',
  campaignClicks: 'Clicks',
  deleteCampaign: 'Remove Campaign',
  //Settings
  settingName: 'Setting name',
  settingValue: 'Setting value',
  deleteSetting: 'Delete',
  createSettingTitle: 'Create new settings',
  settingsFor: 'Settings for ',
  deleteSettingFor: 'Delete setting ',
  createNewMessageMainTitle: 'Create new message',
  messageListSubTitle: 'Displaying all in app messages',
  messageDetailHeader: 'Message details',

  // Weather
  weathers: 'Weather',
  weatherPlaceValue: 'Place',
  weatherLatitudeValue: 'Latitude',
  weatherLongitudeValue: 'Longitude',
  weatherUrlValue: 'Url',
  deleteWeather: 'Delete',
  createWeatherTitle: 'Create new weather',
  weatherFor: 'Weathers for ',
  deleteFor: 'Delete ',

  // User groups
  createUserGroupsTitle: 'Create new user group',
  deleteUserGroups: 'Delete user group',
  userGroupDetailHeader: 'User group details',
  userGroupUpdated: 'User group updated',
  deleteUserGroupFor: 'Delete user group ',

  partnerGroup: 'Partner groups',
  partnerGroupFor: 'Partner groups for ',
  createPartnerGroupTitle: 'Create new partner group',
}

export default en
